import React, { useState, useRef, useEffect } from "react";
import html2canvas from "html2canvas";

import DxLogo from "../../Images/dx-logo.svg";

import style from "./StatusImage.module.scss";

const parseYearMonthDate = (d) => {
  const year = d.getFullYear();
  let month = d.getMonth() + 1;
  const date = d.getDate();

  if (month < 10) {
    month = "0" + month;
  }

  return `${year}-${month}-${date}`;
};

const StatusImage = () => {
  const prioritiesRef = useRef();
  const membersRef = useRef();
  const targetRef = useRef();

  const [priorities, setPriorities] = useState([]);
  const [members, setMembers] = useState([]);
  const [isScreenshotting, setIsScreenshotting] = useState(false);
  const [numberOfTasks, setNumberOfTask] = useState("");
  const [numberOfDeploys, setNumberOfDeploys] = useState("");

  const handleAddPriority = (e) => {
    e.preventDefault();
    const newPriorityValue = prioritiesRef.current.value;

    const newPriority = (
      <li
        contentEditable="true"
        onInput={(e) => {
          console.log(e);
        }}
        className={style["list-item"]}
      >
        {newPriorityValue}
      </li>
    );

    setPriorities([...priorities, newPriority]);

    prioritiesRef.current.value = "";
  };

  const handleAddMember = (e) => {
    e.preventDefault();

    const newMemberValue = membersRef.current.value;

    const newMember = (
      <li contentEditable="true" className={style["list-item"]}>
        {newMemberValue}
      </li>
    );

    setMembers([...members, newMember]);

    membersRef.current.value = "";
  };

  const handleScreenshot = () => {
    setIsScreenshotting(true);

    setTimeout(() => {
      const screenshotTarget = targetRef.current;

      html2canvas(screenshotTarget).then((canvas) => {
        const base64image = canvas.toDataURL("image/png");

        var a = document.createElement("a"); //Create <a>
        a.href = base64image; //Image Base64 Goes here
        a.download = "Image.png"; //File name Here
        a.click(); //Downloaded file

        setIsScreenshotting(false);
      });
    }, 1000);
  };

  return (
    <div className={style["status-image-wrapper"]} ref={targetRef}>
      <div className={style["status-image-inner-wrapper"]}>
        <header className={style["header"]}>
          <div className={style["logo-wrapper"]}>
            <img alt="logo" className={style["logo"]} src={DxLogo} />
          </div>

          {!isScreenshotting && (
            <button onClick={handleScreenshot} className={style["button"]}>
              Generate screenshot
            </button>
          )}
        </header>

        <div className={style.separator}></div>
        <div className={style["status-image-content"]}>
          <div className={style["primary-container"]}>
            <h3>Number of team tasks for the day</h3>
            {!isScreenshotting ? (
              <input
                className={style.field}
                type="text"
                onChange={(e) => setNumberOfTask(e.target.value)}
                value={numberOfTasks}
              />
            ) : (
              <span className={style["field-span"]}>{numberOfTasks}</span>
            )}
            <div className={style.separator}></div>
            <h3>Top high priority projects for senior management:</h3>
            <ul className={style["members-list"]}>{priorities}</ul>
            <form onSubmit={handleAddPriority}>
              {!isScreenshotting && (
                <input
                  type="text"
                  ref={prioritiesRef}
                  className={style.listField}
                  placeholder="Add a priority project"
                />
              )}
            </form>
          </div>
          <div className={style["secondary-container"]}>
            <h3>Number of planned deploys today</h3>
            {!isScreenshotting ? (
              <input
                className={style.field}
                type="text"
                onChange={(e) => setNumberOfDeploys(e.target.value)}
                value={numberOfDeploys}
              />
            ) : (
              <span className={style["field-span"]}>{numberOfDeploys}</span>
            )}
            <div className={style.separator}></div>
            <h3>Top team members with due tasks for the day</h3>
            <ul className={style["members-list"]}>{members}</ul>
            <form onSubmit={handleAddMember}>
              {!isScreenshotting && (
                <input
                  type="text"
                  ref={membersRef}
                  className={style.listField}
                  placeholder="Add a team member"
                />
              )}
            </form>
          </div>
        </div>
        <div className={style.separator}></div>
        <p className={style["date-info"]}>
          Generated on: <span>{parseYearMonthDate(new Date())}</span>
        </p>
      </div>
    </div>
  );
};

export default StatusImage;
